
@import './partials/variables.scss';

.container-fluid{
  padding:0;
}

/* Default margins for the row
are causing complications */
.row{
  margin:0;
}

.row.marginBottom0{
  margin:0 !important;
}

/* For the footer
The subtracted part is the footer + header heights*/
.app-container {
  min-height: calc(80vh - #{toEm(56)});
  // background-color: $very-very-light-gray;
}

.spacer-thin{
  background-color:$hartford-gray;
  height:toEm(2);
  width:100%;
}

.spacer-two{
  height:toEm(10);
  width:100%;
  background-color:$hartford-light-blue;
}

body{
  font-size:16px;
  font-family:$gotham-regular;
  overflow-x:hidden;
  background-color: #f7f7f7;
}
// for bold and strong font is gotham medium.
b, strong {
  font-family: $gotham-medium;
}
body.no-scroll{
  overflow:hidden;
}

/* Useful Style to remove padding in whatever line you are using.
Suggested with any col-* as they can add padding you may not want.*/
.no-padding{
  padding:0 !important;
}

.no-padding-right{
  padding-right :0 !important;
}

.no-padding-left{
  padding-left :0 !important;
}

.no-padding-right{
  padding-right :0 !important;
}

.no-margin{
  margin:0 !important;
}

.no-horizontal-padding{
  padding-left:0 !important;
  padding-right:0 !important;
}
.no-vertical-padding{
  padding-top:0 !important;
  padding-bottom:0 !important;
}

.text-center{
  text-align:center;
}

.text-right{
  text-align:right;
}

.text-left {
  text-align: left;
}

.label-row {
  padding:toEm(5) toEm(0);
}

.button-col{
  text-align:center;
}

.button-spacing{
  padding: toEm(15) 0 0 0;
}
.input-fields-row-margin{
  margin: 0.35em 0;
}
.button-space {
  margin-top:toEm(50);
}
.GothamSSm-Book,.GothamSSm-book {
  //font-family: $gotham-regular;
  font-weight: 400;
  font-style: normal;
}
.boldText{
  font-weight: bold;
}
.GothamSSm-Medium,.GothamSSm-medium {
  //font-family: $gotham-medium;
  font-weight: 500;
  font-style: normal;
}

.GothamSSm-Bold,.GothamSSm-bold{
 // font-family: $gotham-bold;
  font-weight: 700;
  font-style: normal;
}

.gotham-book {
  font-family: $gotham-medium;
  font-weight: 400;
  font-style: normal;
}
.gotham-medium {
  font-family: $gotham-regular;
  font-weight: 500;
  font-style: normal;
}
/** Italicized Versions Below **/

.GothamSSm-Book-Italic,.GothamSSm-book-italic {
  font-family: $gotham-regular;
  font-weight: 400;
  font-style: italic;
}

.GothamSSm-Medium-Italic,.GothamSSm-medium-italic {
  font-family: $gotham-medium;
  font-weight: 400;
  font-style: italic;
}

.GothamSSm-Bold-Italic,.GothamSSm-bold-italic {
  font-family: $gotham-bold;
  font-weight: 700;
  font-style: italic;
}

/* Icons styling */
@media(max-width: $bootstrap-tablet) {
  .icon-svg{
    height:toREm(56);
    width: toREm(68);
  }
  .icon-error{
    height:toEm(25);
    margin-top:toEm(-2);
  }
}

@media(min-width: $bootstrap-tablet) {
  .icon-svg{
    height:toREm(68);
    width: toREm(68);
  }
}

@media(min-width: $bootstrap-medium-desktop) {
  .icon-svg{
    height:toREm(90);
    width: toREm(90);
  }

}

