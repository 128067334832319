/* @import '../../../../../node_modules/hig-ui-kit/app/scss/partials/variables';

Colors
importing from UXKIT variables https://qawww.thehartford.com/sites/frontendstage/uxkit/demo/dist/index.html

Fonts*/
@use "sass:math";
$gotham-regular: HCo Gotham;
$gotham-bold: HCo Gotham;
$gotham-medium: HCo Gotham SSm;
/*Hartford Colors*/
$hartford-white:            #FFFFFF;
$hartford-black:            #000000;
$hartford-dark-gray:        #808080;
$hartford-blue:             #3A5A78;
$hartford-blue-70:          #6E7C95;
$hartford-light-blue:       #B6D3E9;
$blue-light:                 #B6D3E9;
$blue:                       #3A5A78;
$blue-link:                 #3A5A78;
$hartford-light-blue-45:    #DEECF7;
$hartford-light-blue-20:    #F0F6FB;
$hartford-red:              #6E292D;
$hartford-gray:             #484848;
$hartford-gray-70:          #726E6E;
$hartford-gray-45:          #9A9695;
$gray-medium:               #9A9695;
$hartford-gray-20:          #CAC8C8;
$hartford-gray-10:          #CCCCCC;
$hartford-silver:           #C0C0C0;
$hartford-gray-input:       #4F4F4F;
$blue-link-mod:             #206BAC;
$light-gray:                #F6F6F9;
$cta-green:                 #018852;
$timeline-green:            #10A318;
$very-light-gray:           #F9F9F9;
$very-very-light-gray:      #F7F7F7;
$flag-gray:                 #F2F2F2;
$action-red:                #D0021B;
$action-light-red:          #FF0000;
$hart-ab-orange:            #F26722;
$green-halo:                #10A318;
$measure-magenta:           #BD10E0;
$hartford-bright-ab:        #009CD8;
$red-halo:                  #FF5252;
$hart-ab-orange-14:         #F26722;
$hart-focus-blue:           #3A5A78;
$error-red:                 #B83B2F;
$hartford-focus-blue:       #2D4765;
$hartford-orange:           #F7941F;
$hartford-light-grey:       #A0A0A0;
$hartford-cornflower-blue:  #6495ED;
$horizontal-line:           #979797;
$hartford-gray-color:       #3D3D3D;
//Unknown colors?
$light-green:               #93BFAE;
$medium-gray:               #818181;

//Old colors to remove
$white: #FFFFFF;
$mercury: #E5E5E5;
$gull-gray: #9EAFBC;
$tropical-rain-forest: #00814B;
$east-bay: #3A5A78;
$light-grey:#A0A0A0;
$sky-grey:#C0C0C0;
$ocean-grey:#eee;

/*Fonts*/
$gotham-regular: HCo Gotham;
$gotham-bold: HCo Gotham SSm;
$gotham-medium: HCo Gotham SSm;

$GothamSSm-Bold: GothamSSm-Bold;

$GothamSSm-Bold : GothamSSm-Bold;

$gotham-regular-book : GothamSSm-Book;
$gotham-regular-bold : GothamSSm-Bold;


/*Screen Breakpoints*/
$bootstrap-large-desktop: 1200px;
$bootstrap-medium-desktop: 992px;
$bootstrap-tablet:  768px;
$bootstrap-large-mobile: 767px;
$xs-mobile: 400px;
$bootstrap-ipad-pro: 1366px;

/*Common Bootstrap col measurements*/
$col-sm-12-width: 83.33%;
$col-sm-12-offset-1: 8.33%;

/*Positioning
 1em = 16px (or what we want it to be)*/
$basePx: 16; /*Base pixel for conversion*/
@function toEm($pixel){
  @return math.div($pixel, $basePx) + em;
}

@function toREm($pixel){
  @return math.div($pixel, $basePx) + rem;
}

@function sub($a, $b){
  @return ($a - $b) + em;
}

html{
  font-size: $basePx + px;
}
